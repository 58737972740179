export default class SalaryCalc {
  paymentdate: string;
  employeeId: string;
  affiliationId: string;
  worktime: string;
  workdays: string;
  salary1: string;
  salary2: string;
  salary3: string;
  salary4: string;
  inovertime: string;
  overtime: string;
  midnighttime: string;
  midnightovertime: string;
  holidaytime: string;
  paiddays: string;
  lateLeaveTime: string;
  latecount: string;
  earylcount: string;
  absencetime: string;
  nightcount: string;
  commutingallowance: string;
  commutingdistance: string;
  holidayallowance: string;
  paidallownance: string;
  perfectallowance: string;
  inoverallowance: string;
  overallowance: string;
  midnightallowance: string;
  midnightoverallowance: string;
  nightallowance: string;
  otherallowance: string;
  otherallowance1: string;
  otherallowance2: string;
  yearendallowance: string;
  mealbill: string;
  commutingworkcount: string;
  commutingworkallowance: string;
  parkingdailyallowance: string;
  nightguardcount: string;
  nightguardallowance: string;
  emergencycount: string;
  emergencyallowance: string;
  midnighttelcount: string;
  midnighttelallowance: string;
  specialvacationcount: string;
  specialvacationallowance: string;
  welfarekaigo: string;
  welfaresyogai: string;
  minDispFlag: string;
  assistallowance: string;
  assisttime: string;
  holidaydays: string;
  absencecount: string;
  adjustmentDays: string;
  constructor(props: {
    paymentdate: string | undefined;
    employeeId: string | undefined;
    affiliationId: string | undefined;
    worktime: string | undefined;
    workdays: string | undefined;
    salary1: string | undefined;
    salary2: string | undefined;
    salary3: string | undefined;
    salary4: string | undefined;
    inovertime: string | undefined;
    overtime: string | undefined;
    midnighttime: string | undefined;
    midnightovertime: string | undefined;
    holidaytime: string | undefined;
    paiddays: string | undefined;
    lateLeaveTime: string | undefined;
    latecount: string | undefined;
    earylcount: string | undefined;
    absencetime: string | undefined;
    nightcount: string | undefined;
    commutingallowance: string | undefined;
    commutingdistance: string | undefined;
    holidayallowance: string | undefined;
    paidallownance: string | undefined;
    perfectallowance: string | undefined;
    inoverallowance: string | undefined;
    overallowance: string | undefined;
    midnightallowance: string | undefined;
    midnightoverallowance: string | undefined;
    nightallowance: string | undefined;
    otherallowance: string | undefined;
    otherallowance1: string | undefined;
    otherallowance2: string | undefined;
    yearendallowance: string | undefined;
    mealbill: string | undefined;
    commutingworkcount: string | undefined;
    commutingworkallowance: string | undefined;
    parkingdailyallowance: string | undefined;
    nightguardcount: string | undefined;
    nightguardallowance: string | undefined;
    emergencycount: string | undefined;
    emergencyallowance: string | undefined;
    midnighttelcount: string | undefined;
    midnighttelallowance: string | undefined;
    specialvacationcount: string | undefined;
    specialvacationallowance: string | undefined;
    welfarekaigo: string | undefined;
    welfaresyogai: string | undefined;
    minDispFlag: string | undefined;
    assistallowance: string | undefined;
    assisttime: string | undefined;
    holidaydays: string | undefined;
    absencecount: string | undefined;
    adjustmentDays: string | undefined;
  }) {
    const {
      paymentdate = "",
      employeeId = "",
      affiliationId = "",
      worktime = "",
      workdays = "",
      salary1 = "",
      salary2 = "",
      salary3 = "",
      salary4 = "",
      inovertime = "",
      overtime = "",
      midnighttime = "",
      midnightovertime = "",
      holidaytime = "",
      paiddays = "",
      lateLeaveTime = "",
      latecount = "",
      earylcount = "",
      absencetime = "",
      nightcount = "",
      commutingallowance = "",
      commutingdistance = "",
      holidayallowance = "",
      paidallownance = "",
      perfectallowance = "",
      inoverallowance = "",
      overallowance = "",
      midnightallowance = "",
      midnightoverallowance = "",
      nightallowance = "",
      otherallowance = "",
      otherallowance1 = "",
      otherallowance2 = "",
      yearendallowance = "",
      mealbill = "",
      commutingworkcount = "",
      commutingworkallowance = "",
      parkingdailyallowance = "",
      nightguardcount = "",
      nightguardallowance = "",
      emergencycount = "",
      emergencyallowance = "",
      midnighttelcount = "",
      midnighttelallowance = "",
      specialvacationcount = "",
      specialvacationallowance = "",
      welfarekaigo = "",
      welfaresyogai = "",
      minDispFlag = "",
      assistallowance = "",
      assisttime = "",
      holidaydays = "",
      absencecount = "",
      adjustmentDays = ""
    } = props;

    this.paymentdate = paymentdate;
    this.employeeId = employeeId;
    this.affiliationId = affiliationId;
    this.worktime = worktime;
    this.workdays = workdays;
    this.salary1 = salary1;
    this.salary2 = salary2;
    this.salary3 = salary3;
    this.salary4 = salary4;
    this.inovertime = inovertime;
    this.overtime = overtime;
    this.midnighttime = midnighttime;
    this.midnightovertime = midnightovertime;
    this.holidaytime = holidaytime;
    this.paiddays = paiddays;
    this.lateLeaveTime = lateLeaveTime;
    this.latecount = latecount;
    this.earylcount = earylcount;
    this.absencetime = absencetime;
    this.nightcount = nightcount;
    this.commutingallowance = commutingallowance;
    this.commutingdistance = commutingdistance;
    this.holidayallowance = holidayallowance;
    this.paidallownance = paidallownance;
    this.perfectallowance = perfectallowance;
    this.inoverallowance = inoverallowance;
    this.overallowance = overallowance;
    this.midnightallowance = midnightallowance;
    this.midnightoverallowance = midnightoverallowance;
    this.nightallowance = nightallowance;
    this.otherallowance = otherallowance;
    this.otherallowance1 = otherallowance1;
    this.otherallowance2 = otherallowance2;
    this.yearendallowance = yearendallowance;
    this.mealbill = mealbill;
    this.commutingworkcount = commutingworkcount;
    this.commutingworkallowance = commutingworkallowance;
    this.parkingdailyallowance = parkingdailyallowance;
    this.nightguardcount = nightguardcount;
    this.nightguardallowance = nightguardallowance;
    this.emergencycount = emergencycount;
    this.emergencyallowance = emergencyallowance;
    this.midnighttelcount = midnighttelcount;
    this.midnighttelallowance = midnighttelallowance;
    this.specialvacationcount = specialvacationcount;
    this.specialvacationallowance = specialvacationallowance;
    this.welfarekaigo = welfarekaigo;
    this.welfaresyogai = welfaresyogai;
    this.minDispFlag = minDispFlag;
    this.assistallowance = assistallowance;
    this.assisttime = assisttime;
    this.holidaydays = holidaydays;
    this.absencecount = absencecount;
    this.adjustmentDays = adjustmentDays;
  }
}

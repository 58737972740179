<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-monitor-edit"
    midium
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <v-col v-for="(e, i) in summaryList" cols="3" :key="i">
          <v-text-field
            v-model.number="summary[e.value]"
            :label="e.label"
            :prepend-inner-icon="e.prependInnerIcon"
            :suffix="e.suffix"
            type="number"
            outlined
            dense
          />
        </v-col>
      </v-row>
    </v-form>
  </DialogBase>
</template>
<script>
// mixins
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import SalaryCalc from "@/models/SalaryCalc";

export default {
  name: "AttendanceSummaryFixDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  data: () => ({
    targetmonth: "",
    user: {
      incode: "",
      name: ""
    },
    summary: {
      worktime: 0,
      workdays: 0,
      salary1: 0,
      inovertime: 0,
      overtime: 0,
      midnighttime: 0,
      midnightovertime: 0,
      holidaytime: 0,
      paiddays: 0,
      lateLeaveTime: 0,
      inoverallowance: 0,
      overallowance: 0,
      midnightallowance: 0,
      midnightoverallowance: 0,
      otherallowance: 0,
      commutingworkcount: 0,
      commutingworkallowance: 0,
      specialvacationcount: 0,
      assistallowance: 0,
      assisttime: 0,
      holidaydays: 0,
      absencecount: 0,
      adjustmentDays: 0
    },
    edit: false
  }),
  computed: {
    Title() {
      const s = this.targetmonth.split("-");
      const title = s[0] + "年" + s[1] + "月 " + this.user.name + " 合計修正";
      return title;
    },
    summaryList() {
      return [
        {
          value: "worktime",
          label: "勤務時間",
          prependInnerIcon: "mdi-account-clock",
          suffix: this.getWorkTimeSuffix()
        },
        {
          value: "assisttime",
          label: "応援時間",
          type: "mdi-account-clock",
          prependInnerIcon: "",
          suffix: this.getWorkTimeSuffix()
        },
        {
          value: "workdays",
          label: "勤務日数",
          prependInnerIcon: "mdi-calendar-account-outline",
          suffix: "日"
        },
        {
          value: "adjustmentDays",
          label: "調整日数",
          prependInnerIcon: "mdi-calendar-account-outline",
          suffix: "日"
        },
        {
          value: "absencecount",
          label: "欠勤日数",
          prependInnerIcon: "mdi-clock-time-one-outline",
          suffix: "日"
        },
        {
          value: "paiddays",
          label: "有給日数",
          prependInnerIcon: "mdi-calendar-account-outline",
          suffix: "日"
        },
        {
          value: "specialvacationcount",
          label: "特休日数",
          prependInnerIcon: "mdi-calendar-account-outline",
          suffix: "日"
        },
        {
          value: "salary1",
          label: "基本給",
          prependInnerIcon: "mdi-wallet",
          suffix: "円"
        },
        {
          value: "inovertime",
          label: "時間内残業",
          prependInnerIcon: "mdi-clock-time-one-outline",
          suffix: "時間"
        },
        {
          value: "inoverallowance",
          label: "時間内手当",
          prependInnerIcon: "mdi-wallet",
          suffix: "円"
        },
        {
          value: "overtime",
          label: "時間外残業",
          prependInnerIcon: "mdi-clock-time-one-outline",
          suffix: "時間"
        },
        {
          value: "overallowance",
          label: "時間外手当",
          prependInnerIcon: "mdi-wallet",
          suffix: "円"
        },
        {
          value: "midnighttime",
          label: "深夜時間",
          prependInnerIcon: "mdi-clock-time-one-outline",
          suffix: "時間"
        },
        {
          value: "midnightallowance",
          label: "深夜割増",
          prependInnerIcon: "mdi-wallet",
          suffix: "円"
        },
        {
          value: "midnightovertime",
          label: "深夜残業時間",
          prependInnerIcon: "mdi-clock-time-one-outline",
          suffix: "時間"
        },
        {
          value: "midnightoverallowance",
          label: "深夜残業手当",
          prependInnerIcon: "mdi-wallet",
          suffix: "円"
        },
        {
          value: "holidaydays",
          label: "法定日数",
          prependInnerIcon: "mdi-calendar-account-outline",
          suffix: "日"
        },
        {
          value: "holidaytime",
          label: "法定時間",
          prependInnerIcon: "mdi-clock-time-one-outline",
          suffix: "時間"
        },
        {
          value: "lateLeaveTime",
          label: "遅早時間",
          prependInnerIcon: "mdi-clock-time-one-outline",
          suffix: "時間"
        },
        {
          value: "commutingworkcount",
          label: "通勤日数",
          prependInnerIcon: "mdi-calendar-account-outline",
          suffix: "日"
        },
        {
          value: "commutingworkallowance",
          label: "通勤日額",
          prependInnerIcon: "mdi-wallet",
          suffix: "円"
        },
        {
          value: "assistallowance",
          label: "応援手当",
          prependInnerIcon: "mdi-wallet",
          suffix: "円"
        },
        {
          value: "otherallowance",
          label: "その他手当",
          prependInnerIcon: "mdi-wallet",
          suffix: "円"
        }
      ];
    }
  },
  methods: {
    close(result) {
      this.resolve(result);
      this.$close();
    },
    getWorkTimeSuffix() {
      const suffix = this.summary.minDispFlag == 0 ? "時間" : "分";
      return suffix;
    },
    async complete() {
      console.log("complete");

      try {
        const payload = new SalaryCalc({
          paymentdate: this.getPaymentDate(),
          employeeId: this.user.incode,
          affiliationId: "-999",
          worktime: String(this.summary.worktime),
          workdays: String(this.summary.workdays),
          salary1: String(this.summary.salary1),
          inovertime: String(this.summary.inovertime),
          overtime: String(this.summary.overtime),
          midnighttime: String(this.summary.midnighttime),
          midnightovertime: String(this.summary.midnightovertime),
          holidaytime: String(this.summary.holidaytime),
          paiddays: String(this.summary.paiddays),
          lateLeaveTime: String(this.summary.lateLeaveTime),
          inoverallowance: String(this.summary.inoverallowance),
          overallowance: String(this.summary.overallowance),
          midnightallowance: String(this.summary.midnightallowance),
          midnightoverallowance: String(this.summary.midnightoverallowance),
          otherallowance: String(this.summary.otherallowance),
          commutingworkcount: String(this.summary.commutingworkcount),
          commutingworkallowance: String(this.summary.commutingworkallowance),
          specialvacationcount: String(this.summary.specialvacationcount),
          assistallowance: String(this.summary.assistallowance),
          assisttime: String(this.summary.assisttime),
          holidaydays: String(this.summary.holidaydays),
          absencecount: String(this.summary.absencecount),
          adjustmentDays: String(this.summary.adjustmentDays)
        });

        console.log("salaryfix", payload);
        if (this.edit) {
          await this.$put(this.Paths.attendanceSalaryFix, payload);
        } else {
          await this.$post(this.Paths.attendanceSalaryFix, payload);
        }

        this.$info("更新しました。");
        this.$close(true);
      } catch (e) {
        console.log("posterror", e);
        this.$error(e.message);
      }
    },
    getPaymentDate() {
      const ym = this.targetmonth.split("-");
      const dt = new Date(Number(ym[0]), Number(ym[1]), 1);
      dt.setDate(dt.getDate() - 1);

      const result =
        dt.getFullYear() +
        "-" +
        ("000000" + Number(dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("000000" + dt.getDate()).slice(-2);

      return result;
    }
  },
  created() {
    console.log("created", this.args);
    this.targetmonth = this.args.targetmonth;
    this.user.incode = this.args.user.code;
    this.user.name = this.args.user.name;
    this.edit = this.args.edit;
    if (this.edit) this.summary = this.args.summary;
  },
  mounted() {
    console.log("mounted", this.args);
  }
};
</script>

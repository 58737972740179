<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="selectDays"
            :items="days"
            outlined
            multiple
            clearable
            :rules="[Rules.Required]"
            label="日付"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="dialog-padding">
          <v-autocomplete
            v-model="shozoku"
            :items="shozokus"
            outlined
            :rules="[Rules.Required]"
            label="所属"
            @input="shozokuSelect"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
          <v-autocomplete
            v-model="shift"
            :items="shifts"
            outlined
            label="シフト"
            @input="shiftSelect"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <!-- <v-col class="dialog-padding">
          <v-text-field
            v-model.number="distance"
            label="移動距離"
            suffix="km"
            type="number"
            step="0.1"
            prepend-inner-icon="mdi-map-marker-distance"
            outlined
          ></v-text-field>
        </v-col> -->
        <v-col class="dialog-padding">
          <v-text-field
            v-model.number="amount"
            label="単価"
            suffix="円"
            type="number"
            step="1"
            prepend-inner-icon="mdi-wallet"
            outlined
          ></v-text-field>
        </v-col>
        <v-col></v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectWork"
            row
            label="出勤種別:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="通常" :value="SelectsWork.Default"></v-radio>
            <v-radio label="休日" :value="SelectsWork.Break"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectDayCount"
            row
            label="出勤日数:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="1" :value="SelectsDayCount.Default"></v-radio>
            <v-radio label="2" :value="SelectsDayCount.Over"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectsOption"
            row
            label="応援・その他:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="なし" :value="SelectsOption.NONE"></v-radio>
            <v-radio label="応援" :value="SelectsOption.ASSIST"></v-radio>
            <v-radio label="その他" :value="SelectsOption.OTHER"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectAbsence"
            row
            label="欠勤:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="なし" :value="SelectsAbsence.Default"></v-radio>
            <v-radio label="あり" :value="SelectsAbsence.Absence"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <p>出退勤時間はシフトの出勤時間、退勤時間が設定されます。</p>
      </v-row>
    </v-form>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import AttendanceFix from "@/models/AttendanceFix";
import { COMMON_SHIFT_PATTERNS_REF as CommonShiftRefCode } from "@/defines";
import moment from "moment";

moment.locale("ja", {
  weekdays: [
    "日曜日",
    "月曜日",
    "火曜日",
    "水曜日",
    "木曜日",
    "金曜日",
    "土曜日"
  ],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

const Shozokus = [{ name: "", code: "" }];
const Shifts = [];

const SelectsWork = {
  Default: 0,
  Break: 1
};

const SelectsLateLeave = {
  Default: 0,
  Late: 1,
  Leave: 2,
  LateLeave: 3
};

const SelectsDayCount = {
  Default: 1,
  Over: 2
};
const SelectsNightCount = {
  Default: 0,
  Over: 1
};
const SelectsOption = {
  NONE: 0,
  ASSIST: 1,
  OTHER: 2
};
const SelectsAbsence = {
  Default: 0,
  Absence: 1
};
export default {
  name: "AttendanceBulkAddDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],

  computed: {
    Title() {
      return this.username + " 勤務実績一括追加";
    }
  },
  data() {
    return {
      incode: "",
      username: "",
      selectDays: [],
      days: [],
      shozokus: Shozokus,
      shozoku: {},
      shifts: Shifts,
      shift: {},
      toggle: [],
      edit: true,
      recordShifts: {},
      distance: 0,
      amount: 0,
      startTime: "",
      endTime: "",
      breakTime: "",
      selectWork: SelectsWork.Default,
      SelectsWork,
      selectLateLeave: SelectsLateLeave.Default,
      SelectsLateLeave,
      selectDayCount: SelectsDayCount.Default,
      SelectsDayCount,
      selectNightCount: SelectsNightCount.Default,
      SelectsNightCount,
      selectsOption: SelectsOption.NONE,
      SelectsOption,
      selectAbsence: SelectsAbsence.Default,
      SelectsAbsence,
      forPaid: ""
    };
  },
  methods: {
    shozokuSelect() {
      console.log("shozokuSelect", this.shozoku);
      const shozoku = this.shozoku;
      const filtershifts = this.recordShifts.filter(
        e => e.refcode === shozoku || e.refcode === CommonShiftRefCode
      );
      this.shifts = filtershifts;
    },
    shiftSelect() {
      console.log("shiftSelect", this.shift, this.recordShifts);
      const filter = this.recordShifts.filter(
        e => e.code === String(this.shift)
      );
      if (filter.length > 0) {
        const set = filter[0];
        this.startTime = set.option1;
        this.endTime = set.option2;
        this.breakTime = set.option3;
        if (set.refcode === "99999") {
          this.forPaid = set.code;
        } else {
          this.forPaid = "0";
        }
      } else {
        this.startTime = "";
        this.endTime = "";
        this.breakTime = "";
        this.forPaid = "0";
      }
    },
    close(result) {
      this.resolve(result);
      this.$close();
    },
    async complete() {
      console.log("complete");

      if (!this.$refs.form.validate()) return;

      const payloads = [];

      this.selectDays.forEach(day => {
        const payLoad = new AttendanceFix({
          recordId: "",
          employeeId: this.incode,
          affiliationId: this.shozoku,
          targetShiftId: "",
          shiftpatternid: this.shift,
          attendanceDate: day,
          startTime: this.startTime,
          endTime: this.endTime,
          movingDistance: this.distance,
          breaktime: this.breakTime,
          work: "",
          over: "",
          midnight: "",
          midnightover: "",
          inover: "",
          leaveTime: "",
          lateTime: "",
          lateleave: this.selectLateLeave,
          hadmeal: "", // this.selectMeal,
          workclassificationid:
            this.forPaid.length > 0 ? this.forPaid : this.selectWork,
          amount: this.amount,
          reserveAmount1: "",
          reserveAmount2: "",
          reserveid1: this.selectDayCount,
          nightShift: this.selectNightCount,
          careShu: "0",
          careKbn: "0",
          timeKbn: "0",
          nightGuard: "0",
          emergencyFlag: "0",
          midnightTel: "0",
          assistFlag: this.selectsOption === SelectsOption.ASSIST ? "1" : "0",
          otherFlag: this.selectsOption === SelectsOption.OTHER ? "1" : "0",
          absence: this.selectAbsence,
          biko: ""
        });
        payloads.push(payLoad);
      });

      this.$loading();
      try {
        console.log("post parameters", payloads);
        await this.$post(this.Paths.attendanceBulk, payloads);
        await this.$info("登録しました。", "勤務実績");
        console.log("complete end");
        this.$close(true);
      } catch (e) {
        console.log("complete error", e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async getShozoku() {
      console.log("shozoku");
      try {
        const result = await this.$get(this.Paths.shozoku);
        if (result) {
          this.shozokus.length = 0;
          this.shozokus = result;
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async getShiftPattern() {
      console.log("shift-pattern");

      try {
        const result = await this.$get(this.Paths.shiftpatternPulldown);
        if (result) {
          this.shifts.length = 0;
          this.recordShifts.length = 0;
          this.recordShifts = result;
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async checkapproval(payLoads) {
      for (let i = 0; i < payLoads.length; i++) {
        const payLoad = payLoads[0];

        const param = payLoad;
        console.log("getRecordByUser", param);
        const encode = encodeURI(JSON.stringify(param));
        const result2 = await this.$get(
          this.Paths.attendanceapproval,
          "query=" + encode
        );

        console.log(result2.approval);

        if (result2.approval === "1" || result2.approval === "2") {
          return false;
        }
      }
      return false;
    },
    async setDays(fromdate, todate) {
      const fromMoment = moment(fromdate).subtract(1, "month");
      const toMoment = moment(todate).subtract(1, "month");
      while (fromMoment.diff(toMoment) <= 0) {
        this.days.push({
          code: fromMoment.format("YYYY-MM-DD"),
          name: fromMoment.format("YYYY-MM-DD (ddd)")
        });
        fromMoment.add(1, "days");
      }
    }
  },
  async created() {
    console.log("created", this.args);

    this.incode = this.args.incode;
    this.username = this.args.username;
    const fromdate = this.args.fromdate;
    const todate = this.args.todate;

    await this.setDays(fromdate, todate);
    await this.getShozoku();
    await this.getShiftPattern();
  }
};
</script>

<style scoped>
.margin-row {
  margin-top: 0px;
}
.dialog-padding {
  padding-top: 0px;
}
</style>
